var $body = $("body");
//var $favToggle = $(".favourites");
var $favContainer = $("header").find("#favourites-container");
var $close = $favContainer.find(".close-slide");

var $cartToggle = $(".cart");
var $cartContainer = $("header").find("#cart-container");

var $addToCart = $(".add-to-cart");
var $addToCartQuantity = $(".add-to-cart-quantity");

/*$favToggle.on("click", function () {
   "use strict";
   $cartContainer.removeClass("show");
   $favContainer.toggleClass("show");
   $body.toggleClass("overlay");
});*/

$close.on("click", function () {
   "use strict";
    if ($favContainer.hasClass("show")) {
        $favContainer.removeClass("show");
    }
    if ($body.hasClass("overlay")) {
        $body.removeClass("overlay");
    }
});

// $cartToggle.on("click", function () {
//     "use strict";
//     $favContainer.removeClass("show");
//     $cartContainer.toggleClass("show");
//     $body.toggleClass("overlay");
// });

var $closeCart = $cartContainer.find(".close-slide");
$closeCart.on("click", function () {
    "use strict";
    if ($cartContainer.hasClass("show")) {
        $cartContainer.removeClass("show");
    }
    if ($body.hasClass("overlay")) {
        $body.removeClass("overlay");
    }
});

var $accordionToggle = $("#accordionDelivery .card .card-header").find(".toggle");
var $radioButtonCheck = $accordionToggle.find(".radio-button > .radio-inner");
$accordionToggle.on("click", function () {
    "use strict";
    var $radioButton = $(this).find(".radio-inner");
    $radioButtonCheck.removeClass("checked");
    if ($radioButtonCheck.hasClass("checked")) {
        $radioButtonCheck.removeClass("checked");
    } else {
        $radioButton.addClass("checked");
    }
});

var $accordionPaymentToggle = $("#accordionPayment .card .card-header").find(".toggle");
var $radioButtonCheckPayment = $accordionPaymentToggle.find(".radio-button > .radio-inner");
$accordionPaymentToggle.on("click", function () {
    "use strict";
    var $radioButton = $(this).find(".radio-inner");
    $radioButtonCheckPayment.removeClass("checked");
    if ($radioButtonCheckPayment.hasClass("checked")) {
        $radioButtonCheckPayment.removeClass("checked");
    } else {
        $radioButton.addClass("checked");
    }
});

var $paymentToggle = $("#checkout").find(".payment-toggle");
var $paymentContainer = $("#checkout").find("#order-details");
$paymentToggle.on("click", function () {
    $(this).toggleClass("toggle");
   $paymentContainer.toggleClass("expanded");
   $body.toggleClass("overflow-hidden");
});

(function() {
  'use strict';
  window.addEventListener('load', function() {
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          $(form).addClass('has-errors');
        } else {
          $(form).removeClass('has-errors');
          $(form).addClass('submitting');
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);
})();

var Cart = function (container, type) {
    this._body = $("body");
    this._addButton = $(".add-to-cart-button");
    this._addQuantityInput = $(".add-to-cart-quantity");
    this._addOptions = $(".product-options");
    this._cartContainer = container;
    this._type = type;
    this.initialize();
};
Cart.prototype = {
    _body: null,
    _addButton: null,
    _addOptions: null,
    _addQuantityInput: null,
    _changeTimeout: null,
    _changeRequest: null,
    _cartContainer: null,
    _type: null,
    initialize: function () {

        var self = this;

        if (this._type == 'full') {
            $.get('/cart/cart?type=full', {}, function (html) {
                self.openWithHTML(html);
            });
        }
        if (this._type == 'side') {
            this._addButton.on('click', function () {
                var options = [];
    
                if (self._addOptions.length > 0) {
                    var valid = true;
                    self._addOptions.each(function () {
                        if ($(this).find('input:checked').val() != null) {
                            options.push($(this).find('input:checked').val());
                        } else {
                            $(this).addClass('error');
                            valid = false;
                        }
                    });
    
                    if (!valid) {
                        return false;
                    }
                }

                var button = $(this);
    
                $.post('/cart/add', {
                    id: $(this).data('id'),
                    qty: self._addQuantityInput.length == 0 ? 1 : self._addQuantityInput.val(),
                    options: options,
                    mobile: button.hasClass('add-to-cart-mobile')
                }, function (html) {
                    if (button.hasClass('add-to-cart-mobile')) {
                        $("#addToCartPopup").html(html).modal();
                    } else {
                        self.openWithHTML(html);
                        getStatus();
                    }

                });
    
                return false;
            });
    
            this._addOptions.find('input').on('click', function () {
                $(this).parents('.product-options.error').removeClass('error');
            });

            // self._body.on('click', '.add-to-cart-list', function () {

            //     var button = $(this);

            //     $.post('/cart/add', {
            //         id: $(this).parents('.product').data('product'),
            //         qty: 1,
            //         options: []
            //     }, function (html) {

            //         if (button.hasClass('add-to-cart-mobile')) {
            //             $("#addToCartPopup").modal();
            //         } else {
            //             self.openWithHTML(html);
            //         }

            //     });

            //     return false;
            // });

            self._body.find('.side-cart-overlay').on('click', function () {
                self._body.removeClass('overlay');
                self._cartContainer.removeClass('show');
            });
        }
    },

    openWithHTML: function (html) {

        var self = this;

        if (this._type == 'side') {
            self._body.addClass('overlay');
        }
        self._cartContainer.html(html).addClass('show');

        self._cartContainer.find('.close-slide').on('click', function () {
            self._body.removeClass('overlay');
            self._cartContainer.removeClass('show');
        });

        self._cartContainer.find('.remove-button').on('click', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('remove', { 
                id: product.data('id'),
                options: options
            },
            $(this));

            return false;
        });

        self._cartContainer.find('.btn-plus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');

            qty.val(parseInt(qty.val())+1).trigger('blur');

            return false;
        });

        self._cartContainer.find('.btn-minus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');

            qty.val(parseInt(qty.val()-1)).trigger('blur');

            return false;
        });

        self._cartContainer.find('.qty').on('blur', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('qty', { 
                id: product.data('id'),
                qty: product.find('.qty').val(),
                options: options
            }, $(this));

            return false;
        });
    },
    change: function(action, data, element) {
        var self = this;

        if (self._changeRequest != null) {
            self._changeRequest.abort();
        }
        if (self._changeTimeout != null) {
            clearTimeout(self._changeTimeout);
        }

        self._changeTimeout = setTimeout(function () {

            element.parents('.product').find(".quantity").prepend('<div class="list-loader"><div class="inner"><div></div><div></div><div></div></div></div>');

            self._cartContainer.find('.cart-overlay').show();

            self._changeRequest = $.post('/cart/' + action + '?type=' + self._type, data, function (html) {
                self.openWithHTML(html);

                getStatus();
            });
        }, 600);
    }
};

var fullCartPlaceholder = $(".full-cart-placeholder");
var sideCartPlaceholder = $(".side-cart-placeholder");
if (fullCartPlaceholder.length > 0) {
    fullCartPlaceholder.prepend('<div class="list-loader"><div class="inner"><div></div><div></div><div></div></div></div>');
    new Cart(fullCartPlaceholder, 'full');
} else {
    new Cart($(".side-cart-placeholder"), 'side');
}

getStatus();

function getStatus() {
    $.get('/cart/status', function (response) {
        var data = JSON.parse(response);

        $(".icon-cart .count").show().html(data.cart > 0 ? data.cart : '');

        if (data.user != null) {
            $(".account-text").html("Moj račun");
        } else {
            $(".account-text").html("Prijavi se");
        }
    });
}

var Checkout = function (container, type) {
    this.initialize();
};
Checkout.prototype = {

    initialize: function () {
        var self = this;
        if ($("#account-checkbox").is(':checked')) {
            $(".form-password").show().find('input').prop('required', true);
        }
        
        $("#account-checkbox").on('click', function () {
            if ($(this).is(':checked')) {
                $(".form-password").show().find('input').prop('required', true);
            } else {
                $(".form-password").hide().find('input').prop('required', false);
            }
        });

        $(".delivery-address").find('input').prop('required', false);
        $("#delivery-checkbox").on('click', function () {
            if ($(this).is(':checked')) {
                $(".delivery-address").show().find('input').prop('required', true);
            } else {
                $(".delivery-address").hide().find('input').prop('required', false);
            }
        });

        $(".address-card").on('click', function () {
            $(".address-card.active.selected").removeClass(['active', 'selected']);
            
            $(this).addClass(['active', 'selected']);

            var address = $(this).data('address');

            $(".delivery-address input[name=delivery_address_id]").val(address.id);

            Object.keys(address).forEach(function(key) {
                $(".delivery-address input[name=delivery_" + key + "]").val(address[key]);
            });
        });

        $("#delivery input, #payment input").on('change', function () {
            self.saveDeliveryChoice(
                $("input[name=payment_type_id]:checked").val(), 
                $("input[name=delivery_type_id]:checked").val()
            );
        });

        $.get("/cart/checkout-summary", function (html) {
            self.applySummary(html);
        });
    },

    saveDeliveryChoice: function (payment, delivery) {
        var self = this;
        $.post('/cart/checkout-delivery', { 
            payment_type_id: payment != null ? payment : null, 
            delivery_type_id: delivery != null ? delivery : null 
        }, function (html) {
            self.applySummary(html);
        });
    },
    applySummary: function(html) {
        var self = this;
        $(".checkout-summary-placeholder").html(html);

        $(".coupon-form").on('submit', function () {
            var code = $("#discount-code").val();

            $.post('/cart/checkout-coupon', { code: code }, function (html) {
                self.applySummary(html);
            });

            return false;
        });

        $(".coupon-remove").on('click', function () {

            $.get('/cart/checkout-coupon-remove', function (html) {
                self.applySummary(html);
            });

            return false;
        });
    }
};

if ($("#checkout").length > 0) {
    new Checkout();
}